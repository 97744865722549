<template>
    <div>
        <v-app>
            <navigation :color="color" :flat="flat" :textColor="textColor" :logoCompleto="logoCompleto" />
            <v-main>
                <v-row>
                    <v-col>
                        <v-card :loading="loading">
                            <v-img
                                :src="itemsCreditRequests.credit_request_banner.url"
                                class="pt-7"
                            >
                                
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row no-gutters class="" >
                    <v-col cols="12" class="text-center">
                        <v-img contain :src="itemsCreditRequests.credit_request_headband"></v-img>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-card>
                            <v-img
                                src="~@/assets/img/req_bg2.png"
                                class="pt-7"
                            >
                                <v-row no-gutters justify="center">
                                    <v-col
                                        sm="12"
                                        md="3"
                                    >
                                    </v-col>
                                    <v-col
                                        sm="12"
                                        md="6"
                                        class="text-left pr-10"
                                    >
                                        <!-- <p class="text-h4 font-weight-bold secondary--text">
                                            ¿QUE TENGO QUE HACER?
                                        </p>
                                        <p
                                            v-for="item in dataPasos"
                                            :key="item.id"
                                            class="text-subtitle-1 font-weight-bold secondary--text"
                                        >
                                            <span class="text-h4 font-weight-bold secondary--text pr-3">{{item.id}}</span> {{item.descripcion}}
                                        </p> -->
                                        <p class="text-subtitle-1 font-weight-bold secondary--text" v-html="itemsCreditRequests.credit_request_main_text"></p>
                                    </v-col>
                                    <v-col
                                        sm="12"
                                        md="2"
                                    >
                                        <a :href="itemsCreditRequests.credit_request_popup.url" target="_blank" rel="noopener noreferrer">
                                            <v-img
                                                :src="itemsCreditRequests.credit_request_popup.img"
                                                max-height="600"
                                                contain
                                            ></v-img>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </v-main>
            <v-scale-transition>
                <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
                    <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
            </v-scale-transition>
            <customFooter></customFooter>
        </v-app>
    </div>
</template>
  
<script>
import { mapState, mapActions } from "vuex";
import AOS from 'aos';
import navigation from "@/modules/landing/components/Navigation.vue";
import customFooter from "@/modules/shared/layouts/Footer.vue";

// import proc_header from '@/assets/img/proc_header.png';
// import popup from '@/assets/img/popup1.jpeg';
export default {
    name: "Nosotros",
    components: {
        navigation,
        customFooter,
    },

    data: () => ({
        showPopUp: false,
        logoCompleto: null,
        fab: null,
        color: "primary",
        textColor: "white",
        flat: null,
        // itemsCarousel: [
        //     {
        //         src: proc_header,
        //     },
        // ],
        // dataPasos: [
        //     { 
        //         id: 1,
        //         descripcion: 'Llena tu solicitud de crédito, ingresando tu información personal.',
        //     },
        //     { 
        //         id: 2,
        //         descripcion: 'Si tu línea de crédito ha sido aprobada, inmediatamente recibirás notificaciones por email y WhatsApp.',
        //     },
        //     { 
        //         id: 3,
        //         descripcion: 'Ingresa a tu tablero de control Medi aquí para programar desembolsos.',
        //     },
        //     { 
        //         id: 4,
        //         descripcion: 'Indica si el tratamiento será para ti o para algún miembro de tu familia. Compártenos información del procedimiento a realizar y el médico con quien prefieres tratarte.',
        //     },
        //     { 
        //         id: 5,
        //         descripcion: 'Completa la validación de tu identidad, subiendo una foto de tu identificación y un video selfie. Firma tu solicitud de reembolso.',
        //     },
        //     { 
        //         id: 6,
        //         descripcion: 'Al momento de recibir tu tratamiento, MEDI ASSIST realiza el pago directamente al médico o clínica.',
        //     },
        // ]
    }),

    created() {
        this.getItemsCreditRequests();
        const top = window.pageYOffset || 0;
        if (top <= 60) {
            this.color = "primary";
            this.flat = true;
        }
    },
    computed: {
        ...mapState("loader", ["loading"]),
        ...mapState("landing", ["itemsCreditRequests"]),
    },
    mounted() {
        // Actualiza AOS después de que Vue ha renderizado el componente
        this.$nextTick(() => {
            AOS.refresh();
        });
        setTimeout(() => {
            this.showPopUp = true;
        }, 5000);
    },
    methods: {
        ...mapActions({
            getItemsCreditRequests: "landing/getItemsCreditRequests",
        }),
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 60;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        // showAlert() {
        //     this.$swal({
        //         position: "center-end",
        //         imageAlt: "Custom image",
        //         html: `<a href="https://gencell.com.mx/power_serum.php" target="_blank"><img src="${popup}" style="width: auto; height: 500px;"></a>`,
        //         showConfirmButton: false,
        //         background: 'transparent', // Para establecer el fondo transparente
        //         allowOutsideClick: true, // Permitir hacer clic fuera de la alerta para cerrarla
        //         customClass: {
        //         popup: 'custom-popup-class', // Clase CSS personalizada para el contenedor de la alerta
        //         content: 'custom-content-class' // Clase CSS personalizada para el contenido de la alerta
        //         }
        //     });
        // },
    },
    watch: {
        fab(value) {
            if (value) {
                this.color = "dark";
                this.textColor = "white";
                this.flat = false;
                this.logoCompleto = false;
            } else {
                this.color = "primary";
                this.textColor = "white";
                this.flat = true;
                this.logoCompleto = true;
            }
        },
    }
};
</script>
  
<style scoped>
#app {
    background-image: url("~@/assets/img/home_background.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    z-index: 10;
}

.text-shadow{
    text-shadow: -1px 4px 7px rgba(0, 0, 0, 0.9);
}
</style>